<template>
  <div class="app-box">
    <div class="apeMair">
      <p>提现到</p>
      <div class="boerTop">
        <div class="left">
          <img class="imgBoxe" :src="fromData.cardInformation.img" alt="" />
        </div>
        <div class="right">
          <p @click="replace" v-if="fromData.cardInformation.bank_num">
            {{ fromData.cardInformation.bank
            }}*****{{fromData.cardInformation.bank_num.substring(fromData.cardInformation.bank_num.length - 4)}}<van-icon name="arrow" class="iconrit" />
          </p>
          <p class="font_yiji">预计15号内到账</p>
        </div>
      </div>
    </div>
    <div class="fromInput">
      <p>提现金额</p>
      <div class="fonteiSize">
        ¥<input
          class="fontInput"
          @click="show = true"
          type="text"
          readonly
          :value="fromData.cashout"
        />
       
      </div>
      <p class="colortie" v-if="tixType">输入金额超过可提现金额</p>
      <div class="bototmCent">
        <p class="sizeColor">当前可提现余额{{tisize}}元</p>
        <p class="sizeColor sizeColor1" @click="addele">全部提现</p>
      </div>
    </div>
    <p class="textimportant">
      * 申请提现历史收益的时间为每月1-15号，既本月收益需下月申请
      提现，提现到账时间为每月20号到账，如遇节假日则顺延。且提现
      金额不能大于账户余额。
    </p>
    <div>
      <van-number-keyboard
        :show="show"
        extra-key="."
        close-button-text="完成"
        @blur="show = false"
        theme="custom"
        @input="onClickInputKeyBoard"
        @delete="onDeleteKeyBoard"
      />
    </div>
    <div class="boepBottom">
      <van-button type="info" size="large" @click="trruna">提现</van-button>
    </div>
    <!-- end提现成功 -->
    <div class="popupE" v-show="successShow"></div>
    <div class="successBox" v-show="successShow">
      <div>
        <p class="textTel">
          如遇问题，请联系客服：<a href="tel:400-861-8683">400-861-8683</a>
        </p>
      </div>
      <van-icon name="cross" class="icondace" @click="successShow = false" />
    </div>
    <!-- end切换银行卡 -->
    <div class="iontme">
      <van-popup v-model="bankCard" position="bottom">
        <h3 class="titleXz">选择提现账号</h3>
        <van-radio-group v-model="fromData.radio">
          <van-cell-group>
            <van-cell
              v-for="(item, index) in cardlist"
              :key="index"
              :title="item.bank +'*****'+ item.bank_num.substring(item.bank_num.length - 4)"
              :icon="item.img"
              clickable
              @click="fromData.radio = item.id"
            >
              <template #right-icon>
                <van-radio :name="item.id">
                  <template #icon="props">
                    <img
                      class="img-icon"
                      :src="props.checked ? activeIcon : inactiveIcon"
                    />
                  </template>
                </van-radio>
              </template>
            </van-cell>
          </van-cell-group>
        </van-radio-group>
        <div class="bottomBpoe">
          <van-button type="info" block @click="bottomPost">确认</van-button>
        </div>
      </van-popup>
    </div>
    <Loding ref="loding" @load="load"></Loding>
  </div>
</template>

<script>
import { Toast } from "vant";
import {mapState} from "vuex"
import{listscard,withdrawal,verifyWithdrawal} from "@/request/api"
import Loding from "@/components/Loding/Loding"
export default {
    components:{
    Loding
  },
  data() {
    return {
      fromData: {
        cashout: "", //提现金额
        radio: "", //
        cardInformation: {}, //提现银行卡信息
      },
      activeIcon: require("@/assets/icon/orekdcur.png"),
      inactiveIcon: require("@/assets/icon/orekd.png"),
      show: true,
      tixType: false, //为true阻止提交
      tisize: '', //可提现的金额
      successShow: false, //提现成功显示
      bankCard: false,
      cardlist: [], //银行卡信息
    };
  },
  computed:{
    ...mapState(['infoArr'])
  },
  watch: {
    // 监听提现金额大于提现金额提示
    fromData: {
      handler(val, oldVal) {
        if (val.cashout > this.tisize) {
          this.tixType = true;
        } else if (val.cashout <= this.tisize) {
          this.tixType = false;
        }
      },
      deep: true,
    },
  },
  created() {
    this.asrtVerification();
    this.getadd();
  },
  methods: {
    load(){
      this.$refs.loding.loding = true
      this.$refs.loding.loderror = false
      this.getadd();
    },
    // 获取现在可提现金额
     asrtVerification(){
      verifyWithdrawal().then(res=>{
        this.tisize = parseInt(res.data.paybank_withdrawable_balance) 
      });
      
    },
    // 请求银行卡信息
     getadd() {
        listscard().then(res=>{
          // let wechat_account = {bank:'微信钱包',bank_num:this.infoArr.collectionAccount.wechat_account,img: "https://img.menghn.com/wx.png",type:'transfer'}
          if(res.code ==1005){
              Toast.fail('暂无银行卡,请先添加银行卡')
              let path = '/Bank';
              this.$router.push({path, query: {redirect:this.$router.history.current.path}});
              return
          }
          this.$refs.loding.loding = false
          res.data.map((item)=>{
          item['img'] = 'https://img.menghn.com/gs.png',
          item['type'] = 'paybank'
          })
          // res.data.unshift(wechat_account)
          this.cardlist = res.data;
          this.fromData.cardInformation = res.data[0];
          this.fromData.radio = res[0];
        }).catch(error=>{
        this.$refs.loding.loderror = true
        this.$refs.loding.loding = false
      })
    },
    addele() {
      this.fromData.cashout = this.tisize.toString(); //转字符串
    },
    trruna() {
      if (this.tixType) {
        Toast("输入金额大于提现金额");
        return;
      }
      if (!this.fromData.cashout) {
        Toast("请输入提现金额");
        return;
      }
      // 验证通过 提现
      Toast.loading({
        message: "提现中...",
        forbidClick: true,
        duration: 0,
      });
      let data = {
        amount:this.fromData.cashout,
        method:this.fromData.cardInformation.type,
        bank_num:this.fromData.cardInformation.bank_num
      }
      withdrawal(data).then(res=>{
        if(res.code == 1002){
          Toast.fail(res.message)
          return
        }
        Toast.clear();
        this.successShow = true;
        this.$store.dispatch('info')//更新新用户信息
        this.asrtVerification();
        this.fromData.cashout = '';
      })
      // let time = setInterval(() => {
      //   Toast.clear();
      //   this.successShow = true;
      //   console.log(this.fromData);
      //   clearInterval(time);
      // }, 1000);
    },
    replace() {
      this.bankCard = true;
    },
    bottomPost() {
      let res = this.cardlist.filter((item) => item.id === this.fromData.radio);
      res.forEach((item) => {
        this.fromData.cardInformation = item;
      });
      this.bankCard = false;
    },
    onClickInputKeyBoard(value) {
      this.fromData.cashout += value;
      // 限制只能输入一个小数点及两位小数
      this.fromData.cashout = this.fromData.cashout.toString().match(/^\d*(\.?\d{0,2})/g)[0] || null;
    },
    onDeleteKeyBoard() {
      let flag = true;
      if (flag) {
        // 删除掉字符串最后一个
        this.fromData.cashout = this.fromData.cashout.substring(0,this.fromData.cashout.length - 1);
        if (this.fromData.cashout.length === 0) {
          flag = false;
          return false;
        }
      }
    },
  },
};
</script>

<style scoped>
.app-box {
  min-height: 100vh;
  background-color: #edf2f5ff;
  box-sizing: border-box;
  padding-top: 46px;
}
.apeMair {
  padding: 10px;
  box-sizing: border-box;
}
.boerTop {
  overflow: hidden;
  font-size: 20px;
}
.boerTop .left {
  margin-top: 10px;
  width: 45px;
  height: 45px;
  border-radius: 50%;
  background: #fff;
  float: left;
  position: relative;
}
.boerTop .right {
  margin-top: 10px;
  margin-left: 50px;
}
.iconrit {
  float: right;
  margin-top: 6px;
}
.font_yiji {
  color: #5f6781ff;
  font-size: 15px;
  margin-top: 3px;
}
.fromInput {
  width: 95%;
  margin: auto;
  margin-top: 20px;
  background: #fff;
  border-radius: 10px;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden;
}
.fonteiSize {
  font-size: 30px;
  margin-top: 40px;
}
.fonteiSize .fontInput {
  border: none;
  border-bottom: 1px solid #f3f3f5ff;
  width: 90%;
  padding-left: 5px;
  padding-bottom: 3px;
}
.bototmCent {
  display: flex;
  padding: 6px 0;
}
.bototmCent .sizeColor {
  color: #999dadff;
  font-size: 14px;
  margin-top: 5px;
  flex: 1;
}
.bototmCent .sizeColor1 {
  text-align: right;
  color: #0e8affff;
}
.textimportant {
  color: #999dadff;
  padding: 10px;
  box-sizing: border-box;
  font-size: 14px;
}
.colortie {
  color: red;
  font-size: 15px;
}
.boepBottom {
  width: 70%;
  margin: auto;
}
.boepBottom ::v-deep.van-button {
  border-radius: 10px;
}
.popupE {
  width: 100%;
  height: 100vh;
  z-index: 100;
  position: fixed;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.8);
}
.successBox {
  position: fixed;
  left: 50%;
  top: 50%;
  width: 270px;
  height: 273px;
  transform: translate(-50%, -50%);
  z-index: 101;
  background: url(../assets/img/withdrawalscor.png);
  background-size: 100% 100%;
}
.textTel {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 35px;
}
.textTel1 {
  bottom: 15px;
}
.icondace {
  color: #fff;
  border: 1px solid #fff;
  border-radius: 50%;
  position: absolute;
  left: 50%;
  bottom: -50px;
  transform: translate(-50%, 0);
  font-size: 20px;
}
.successBox1 {
  background: url(../assets/img/error.png);
  background-size: 100% 100%;
  left: 50%;
  top: 50%;
}
.textTel1 ::v-deep .seanns {
  border-radius: 10px;
  padding: 0 70px;
}
.typesize2 {
  color: #0e8affff !important;
}
.img-icon {
  width: 20px;
  height: 20px;
}
.titleXz {
  text-align: center;
  font-size: 20px;
  padding: 10px 0;
}
.iontme ::v-deep .van-cell__left-icon {
  margin-right: 10px;
}
.iontme ::v-deep .van-icon__image {
  margin-top: 4px;
}
.bottomBpoe {
  padding: 20px 50px;
  box-sizing: border-box;
}
.bottomBpoe ::v-deep .van-button--block {
  border-radius: 10px;
}
.imgBoxe {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
::v-deep .van-cell{
    padding: 15px 16px;
}
::v-deep .van-nav-bar .van-icon {
		color: #000;
	}
</style>